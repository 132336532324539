import {Serializable} from '../../core/entities/serializable.model';

export class ReportModel extends Serializable {

    public base_64: string;
    public file_name: string;

    newModel(data: any): ReportModel {
        return new ReportModel(data);
    }
}
