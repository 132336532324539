import {OnInit, OnDestroy, Directive} from '@angular/core';
import {Subject} from 'rxjs';
import {FormGroup} from '@angular/forms';

@Directive()
export class BaseComponent implements OnInit, OnDestroy {

    public componentDestroyed = new Subject();

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.unsubscribe();
    }

    hasErrorRequired(form: FormGroup, field: string): boolean {
        return form.get(field).hasError('required');
    }

    hasErrorEmail(form: FormGroup, field: string): boolean {
        return form.get(field).hasError('email');
    }

    hasErrorMinLength(form: FormGroup, field: string): boolean {
        return form.get(field).hasError('minlength');
    }

    hasErrorMatchPassword(form: FormGroup, field: string): boolean {
        return form.get(field).hasError('MatchPassword');
    }
}
