import {Serializable} from '../../core/entities/serializable.model';
import {TrailerModel} from './trailer.model';



export class TruckModel extends Serializable {

    public id: number;
    public plateNumber: string;
    public tara: number;
    public titleFile: string;
    public brand: string;
    public year: number;
    public color: string;
    public insuranceFile: string;
    public chassisNumber: string;

    public insurance: string;
    public insuranceNumber: string;
    public insuranceDueDate: Date;

    public trailer: TrailerModel;

    public techniqueDueDate: string;


    newModel(data: any): TruckModel {
        const t = new TruckModel(data);
        if (data.trailer) {
            t.trailer = TrailerModel.createOne(data.trailer, new TrailerModel());
        } else {
            t.trailer = null;
        }
        return t;

    }


    prepareForStore(): any {
        return {
            plate_number: this.plateNumber,
            tara: this.tara,
            title_file_base64: this.titleFile,
            brand: this.brand,
            year: this.year,
            color: this.color,
            insurance_file_base64: this.insuranceFile,
            chassisNumber: this.chassisNumber,
        };
    }
}
