import { Serializable } from "app/core/entities/serializable.model";
import { OrderLoadModel } from "app/main/orders/models/order-load.model";
import { OrderTypeModel } from "app/main/orders/models/order-type.model";
import { LanguageModel } from "app/shared/models/language.model";
import { ContractTransporterModel } from "./contract-transporter.model";
import { EndorsementModel } from "./endorsement.model";


export class ContractModel extends Serializable {
    public id: number;
    public number: string;
    public transporter: string;
    public transporterAddress: string;
    public transporterCep: string;
    public transporterPhone: string;
    public transporterEmail: string;
    public transporterCityCountry: string;
    public transporterCnpj: string;
    public date: string;
    public companyName: string;
    public companyCnpj: string;
    public companyPermissionName: string;
    public companyPermissionCnpj: string;
    public truckPlate: string;
    public trailerPlate: string;
    public truckBrand: string;
    public truckYear: string;
    public senderName: string;
    public senderCnpj: string;
    public senderRg: string;
    public senderAddress: string;
    public senderPhone: string;
    public senderCity: string;
    public senderUf: string;
    public senderCountry: string;
    public truckerName: string;
    public truckerCpf: string;
    public truckerRg: string;
    public truckerAddress: string;
    public truckerCnh: string;
    public truckerPhone: string;
    public truckerCity: string;
    public truckerUf: string;
    public truckerCountry: string;
    public origin: string;
    public destiny: string;
    public coin: string;
    public dailyChange: number;
    public transportCost: number;
    public cashAdvance: number;
    public extraCost: number;
    public pedagioCost: number;
    public inssCost: number;
    public irrfCost: number;
    public sestCost: number;
    public otherCost: number;
    public cashToPay: number;
    public paymentDetails: string;
    public paymentCondition: string;
    public pendingPaymentDetails: string;
    public pendingPaymentCondition: string;
    public observations: string;
    public totalCostText: string;
    public cashAdvanceText: string;
    public cashPendingText: string;
    public transporterFirm: string;
    public truckerFirm: string;
    public printCashAdvance: number;
    public endorsements:EndorsementModel[];
    public orderType: OrderTypeModel;
    public language: LanguageModel;
    public contractTransporter: ContractTransporterModel;
    public ordersLoad: OrderLoadModel[];
    

    get hasEndorsements():boolean{

      return this.endorsements.length>0;

    }

    newModel(data: any): ContractModel {
        const model = new ContractModel(data);

        return model;
    }

    prepareForStore(): any {
        let data = {
            id: this.id ?? null,
            number: this.number,
            transporter: this.transporter,
            transporter_address: this.transporterAddress,
            transporter_cep: this.transporterCep,
            transporter_phone: this.transporterPhone,
            transporter_email: this.transporterEmail,
            transporter_city_country: this.transporterCityCountry,
            transporter_cnpj: this.transporterCnpj,
            date: this.date,
            company_name: this.companyName,
            company_cnpj: this.companyCnpj,
            company_permission_name: this.companyPermissionName,
            company_permission_cnpj: this.companyPermissionCnpj,
            truck_plate: this.truckPlate,
            trailer_plate: this.trailerPlate,
            truck_brand: this.truckBrand,
            truck_year: this.truckYear,
            sender_name: this.senderName,
            sender_cnpj: this.senderCnpj,
            sender_rg: this.senderRg,
            sender_address: this.senderAddress,
            sender_phone: this.senderPhone,
            sender_city: this.senderCity,
            sender_uf: this.senderUf,
            sender_country: this.senderCountry,
            trucker_name: this.truckerName,
            trucker_cpf: this.truckerCpf,
            trucker_rg: this.truckerRg,
            trucker_address: this.truckerAddress,
            trucker_cnh: this.truckerCnh,
            trucker_phone: this.truckerPhone,
            trucker_city: this.truckerCity,
            trucker_uf: this.truckerUf,
            trucker_country: this.truckerCountry,
            origin: this.origin,
            destiny: this.destiny,
            coin: this.coin,
            daily_change: this.dailyChange,
            transport_cost: this.transportCost,
            cash_advance: this.cashAdvance,
            extra_cost: this.extraCost,
            pedagio_cost: this.pedagioCost,
            inss_cost: this.inssCost,
            irrf_cost: this.irrfCost,
            sest_cost: this.sestCost,
            other_cost: this.otherCost,
            cash_to_pay: this.cashToPay,
            payment_details: this.paymentDetails,
            payment_condition: this.paymentCondition,
            pending_payment_details: this.pendingPaymentDetails,
            pending_payment_condition: this.pendingPaymentCondition,
            observations: this.observations,
            total_cost_text: this.totalCostText,
            cash_advance_text: this.cashAdvanceText,
            cash_pending_text: this.cashPendingText,
            transporter_firm: this.transporterFirm,
            trucker_firm: this.truckerFirm,

        };

        data['orderType'] = {
            id: this.orderType.id,
            name: this.orderType.name,
            tag: this.orderType.tag
        }; 
        data['language'] = {
            id: this.language.id,
            name: this.language.name,
            tag: this.language.tag
        }; 

        data['contractTransporter'] = {
            'id': this.contractTransporter.id ?? null,
            'transporter': this.contractTransporter.transporter,
            'transporter_address': this.contractTransporter.transporterAddress,
            'transporter_cep': this.contractTransporter.transporterCep,
            'transporter_phone': this.contractTransporter.transporterPhone,
            'transporter_email': this.contractTransporter.transporterEmail,
            'transporter_city_country': this.contractTransporter.transporterCityCountry,
            'transporter_cnpj': this.contractTransporter.transporterCnpj,           
        };

         

        data['ordersLoad'] = this.saveDatas(this.ordersLoad as  OrderLoadModel[]);

        return data;
    }

    saveDatas(datas: OrderLoadModel[]) {
        var dataToReturn = [];
        datas.forEach(element => {
            let data = {
                id: element.id ?? null,
            };
            dataToReturn.push(data);
        });
        return dataToReturn;
    }

    
}
