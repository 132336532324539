export const locale = {
  lang: 'es',
  data: {
    'CRT y MIC': 'CRT y MIC',
    'CRT': 'CRT',
    'Pedidos': 'Pedidos',
    'home':'home',
    'Usuarios':'Usuarios',
    'Usuarios de clientes':'Usuarios de clientes',
    'Camioneros':'Camioneros',
    'Administradores':'Administradores',
    'Aduana':'Aduana',
    'Compañía':'Compañía',
    'Noticias':'Noticias',
    'Reportes':'Reportes',
    'Reporte de Pedidos':'Reporte de Pedidos'
  }
}