import {Serializable} from '../../core/entities/serializable.model';


export class LocationModel extends Serializable {

    public id: number;
    public address: string;
    public lat: string;
    public lng: string;
    public address_fantasy: string;

    newModel(data: any): LocationModel {
        return new LocationModel(data);
    }

    static createEmpty(): LocationModel {
        const location = new LocationModel();
        location.address = "";
        location.lat = "";
        location.lng = "";
        location.address_fantasy = "";

        return location;
    }

    prepareForStore(): any {
        return {
            address: this.address,
            lat: this.lat,
            lng: this.lng,
            address_fantasy: this.address_fantasy,
            };
    }
}