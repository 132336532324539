import {Serializable} from '../../core/entities/serializable.model';


export class LanguageModel extends Serializable {

    public id: number;
    public name: string;
    public tag: string;



    newModel(data: any): LanguageModel {
        return new LanguageModel(data);
    }


}