import { Injectable } from '@angular/core';
import { BaseService } from '../../core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralResponse } from '../models/general-response.model';
import { catchError, map } from 'rxjs/operators';
import { TruckerModel } from '../models/trucker.model';
import { FilterService } from './filter.service';
import { FilterModel } from '../models/filter.model';
import { OrderModel } from '../../main/orders/models/order.model';

@Injectable({
    providedIn: 'root'
})
export class TruckerService extends BaseService {
    constructor(
        public router: Router,
        public http: HttpClient,
        private filtersService: FilterService
    ) {
        super(router);

        this.filters = [
            new FilterModel('name', '', false),
            new FilterModel('email', '', false),
            new FilterModel('country', '', false),
            new FilterModel('company', '', false),
            new FilterModel('last_name', '', false),
            new FilterModel('plate_number', '', false),
            new FilterModel('partial_name', '', false),
            new FilterModel('partial_last_name', '', false)
        ];
    }

    store(data: TruckerModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}truckers`;
        return this.http
            .post<GeneralResponse>(url, data.prepareForStore())
            .pipe(
                map(res => {
                    return new GeneralResponse('', false, false, res.data);
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }

    getAll(): Observable<{} | GeneralResponse> {
        return this.getAllWithFilters(
            this.filtersService.formatForURL(this._filters)
        );
    }

    getAllWithFilters(filters: string): Observable<{} | GeneralResponse> {
        const url = `${this._api}truckers?include=user,company&${filters}`;

        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                const data = TruckerModel.createArray(
                    res.data,
                    new TruckerModel()
                );
                return new GeneralResponse('', false, false, data);
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    getTruckerMic(page, size): Observable<{} | GeneralResponse> {
        return this.getAllWithTrucks(
            page,
            size,
            this.filtersService.formatForURL(this._filters)
        );
    }

    getAllWithTrucks(
        page,
        size,
        filters: string
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}truckers-mic?include=user,company,companyPermission,truck,truck.trailer,country&${filters}page[number]=${page +
            1}&page[size]=${size}`;

        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                const data = TruckerModel.createArray(
                    res.data,
                    new TruckerModel()
                );
                return new GeneralResponse('', false, false, data, res.links,
                    res.meta);
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    getOne(truckerSelected: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}truckers/${truckerSelected}?include=user,company,companyPermission,truck,truck.trailer,country`;

        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                return new GeneralResponse(
                    '',
                    false,
                    false,
                    TruckerModel.createOne(res.data, new TruckerModel())
                );
            }),
            catchError(err => {

                return this.handleError(err);
            })
        );
    }

    update(trucker: TruckerModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}truckers/${trucker.id}`;
        return this.http
            .put<GeneralResponse>(url, trucker.prepareForUpdate())
            .pipe(
                map(res => {
                    return new GeneralResponse(
                        '',
                        false,
                        false,
                        TruckerModel.createOne(res.data, new TruckerModel())
                    );
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }

    delete(trucker: TruckerModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}truckers/${trucker.id}`;
        return this.http.delete<GeneralResponse>(url).pipe(
            map(res => {
                return new GeneralResponse('', false, false, res.data);
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    getTruckesWithDocumentExpired(): Observable<{} | GeneralResponse> {
        const url = `${this._api}truckers-with-document-expired?include=user`;
        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                return new GeneralResponse(
                    '',
                    false,
                    false,
                    TruckerModel.createArray(res.data, new TruckerModel())
                );
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }
}
