import { Injectable } from '@angular/core';
import { BaseService } from '../../core/services/base.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FilterService } from '../../shared/services/filter.service';
import { FilterModel } from '../../shared/models/filter.model';
import { Observable } from 'rxjs';
import { GeneralResponse } from '../../shared/models/general-response.model';
import { catchError, map } from 'rxjs/operators';
import { OrderModel } from './models/order.model';
import { OrderFollowUpModel } from './models/order-follow-up.model';
import { OrderLocationModel } from './models/order-location.model';
import { OrderDocumentModel } from './models/order-document.model';
import { OrderImageModel } from './models/order-image.model';
import { OrderLoadModel } from './models/order-load.model';
import { ReportModel } from 'app/shared/models/report.model';
import { OrderUnloadModel } from './models/order-unload';
import { DatePipe } from '@angular/common';
import { OrderReceptionModel } from './models/order-reception.model';
import { ChecklistTruckModel } from './models/checklist-truck.model';

@Injectable({
    providedIn: "root",
})
export class OrderService extends BaseService {
    constructor(
        public router: Router,
        public http: HttpClient,
        private filterService: FilterService,
        public datepipe: DatePipe
    ) {
        super(router);
        this.filters = [
            new FilterModel("status", "", false),
            new FilterModel("number", "", false),
            new FilterModel("trucker", "", false),
            new FilterModel("orderType", "", false),
            new FilterModel("cargoType", "", false),
            new FilterModel("company", "", false),
            new FilterModel("crts.crt_number", "", false),
            new FilterModel("exporterData", "", false),
            new FilterModel("importerData", "", false),
            new FilterModel("bill_number", "", false),
            new FilterModel("remito", "", false),
            new FilterModel("reference", "", false),
            new FilterModel("contract", "", false),
            new FilterModel("orderNumber", "", false),
            new FilterModel("pick_up_date", "", false),
            new FilterModel("orderFilterByFrom", "", false),
            new FilterModel("orderFilterByTo", "", false),
            new FilterModel("billing_number", "", false),
            new FilterModel("filterBilled", "", false),
            new FilterModel("seller", "", false),
            new FilterModel("current_follow_up", "", false),
            new FilterModel("orderDeposit", "", false),
            new FilterModel("orderFilterByCrossingFrom", "", false),
            new FilterModel("orderFilterByCrossingTo", "", false),
            new FilterModel("hasTruck", "", false),
            new FilterModel("crossingRequest", "", false),
        ];
    }

    private companyControlState: string = '';

    getOrders(page, size): Observable<{} | GeneralResponse> {
        return this.getOrdersWithFilters(
            page,
            size,
            this.filterService.formatForURL(this._filters)
        );
    }

    getOrdersWithFilters(
        page,
        size,
        filters: string
    ): Observable<{} | GeneralResponse> {
        const url = `${
            this._api
        }orders?include=company,orderType,cargoType,countryFrom,countryTo,users,crts,branch,branch.customPermits,exporterData,importerData,followUps,followUps.orderTypeStage,followUps.user,orderPriceList&${filters}page[number]=${
            page + 1
        }&page[size]=${size}&sort=-created_at`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                const data = OrderModel.createArray(res.data, new OrderModel());
                return new GeneralResponse(
                    "",
                    false,
                    false,
                    data,
                    res.links,
                    res.meta
                );
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getOne(orderId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders/${orderId}?include=company,orderType,cargoType,countryFrom,countryTo,users,crts,branch,branch.customPermits,orderLoads,orderLoads.orderUnload,orderLoads.orderUnload.orderImage,followUps,followUps.orderTypeStage,followUps.user,exporterData,importerData,orderReception,orderPriceList.priceList,company.priceLists`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse(
                    "",
                    false,
                    false,
                    OrderModel.createOne(res.data, new OrderModel())
                );
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getOneDetail(orderId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders/${orderId}?include=company,countryFrom,countryTo,orderType,cargoType,images,documents,orderTruckerHistory.trucker.user,images,documents.user,crts,branch,branch.customPermits,orderLoads,orderLoads.orderUnload,orderLoads.orderUnload.orderImage,orderLoads.checkTruck,orderLoads.checkTruck.trucker,orderLoads.checkTruck.truck,orderLoads.checkTruck.trailer,orderLoads.checkTruck.checklistPoints,followUps,followUps.orderTypeStage,followUps.user,crts.mics,exporterData,importerData,orderReception,crts.receiver,crts.consignee,crts.notify,crts.crtMicPermits,crts.crtExpenses,crts.sender,crts.creator,crts.modifiedBy,orderPriceList.priceList`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse(
                    "",
                    false,
                    false,
                    OrderModel.createOne(res.data, new OrderModel())
                );
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    store(order: OrderModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders`;
        return this.http
            .post<GeneralResponse>(url, order.prepareForStore())
            .pipe(
                map((res) => {
                    if (res.error) {
                        return new GeneralResponse(
                            res.message,
                            true,
                            false,
                            ""
                        );
                    } else {
                        return new GeneralResponse(
                            "",
                            false,
                            false,
                            OrderModel.createOne(res.data, new OrderModel())
                        );
                    }
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    update(order: OrderModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders/${order.id}?XDEBUG_SESSION_START=PHPSTORM`;
        return this.http
            .put<GeneralResponse>(url, order.prepareForStore())
            .pipe(
                map((res) => {
                    if (res.error) {
                        return new GeneralResponse(
                            res.message,
                            true,
                            false,
                            ""
                        );
                    } else {
                        return new GeneralResponse(
                            "",
                            false,
                            false,
                            OrderModel.createOne(res.data, new OrderModel())
                        );
                    }
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    delete(orderId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders/${orderId}`;
        return this.http.delete<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse("", false, false, res.data);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    changeStatusOrder(data, orderId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}change-status-order/${orderId}`;
        return this.http.post<GeneralResponse>(url, data).pipe(
            map((res) => {
                if (res.error) {
                    return new GeneralResponse(res.message, true, false, "");
                } else {
                    return new GeneralResponse(res.message, false, false, "");
                }
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    changeStatusOrderLoad(
        data,
        orderId: number
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}change-status-order-load/${orderId}`;
        return this.http.post<GeneralResponse>(url, data).pipe(
            map((res) => {
                if (res.error) {
                    return new GeneralResponse(res.message, true, false, "");
                } else {
                    return new GeneralResponse(res.message, false, false, "");
                }
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    storeFollowUp(
        followUp: OrderFollowUpModel,
        orderId: number
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-follow-ups/${orderId}`;
        return this.http
            .post<GeneralResponse>(url, followUp.prepareForStore())
            .pipe(
                map((res) => {
                    return new GeneralResponse(
                        "",
                        false,
                        false,
                        OrderModel.createOne(res.data, new OrderModel())
                    );
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    updateFollowUp(
        followUp: OrderFollowUpModel
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-follow-ups/${followUp.id}`;
        return this.http
            .put<GeneralResponse>(url, followUp.prepareForStore())
            .pipe(
                map((res) => {
                    return new GeneralResponse(
                        "",
                        false,
                        false,
                        OrderFollowUpModel.createOne(
                            res.data,
                            new OrderFollowUpModel()
                        )
                    );
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    deleteFollowUp(followUpId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-follow-ups/${followUpId}`;
        return this.http.delete<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse("", false, false, res.data);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    approveImage(imageId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-images/approve/${imageId}`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse("", false, false, res.data);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    approveDocument(documentId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-documents/approve/${documentId}`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse("", false, false, res.data);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    editImage(data: any, id: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-images/${id}`;
        return this.http.put<GeneralResponse>(url, data).pipe(
            map((res) => {
                return new GeneralResponse("", false, false, res.data);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    deleteImage(id: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-images/${id}`;
        return this.http.delete<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse("", false, false, res.data);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    storeOrderImage(
        image: OrderImageModel,
        orderId: number
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-images/${orderId}`;

        return this.http
            .post<GeneralResponse>(url, image.prepareForStore())
            .pipe(
                map((res) => {
                    return new GeneralResponse(
                        "",
                        false,
                        false,
                        OrderModel.createOne(res.data, new OrderModel())
                    );
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    deleteOrderDocument(id: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-documents/${id}`;
        return this.http.delete<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse("", false, false, res.data);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    storeOrderDocument(
        documents: OrderDocumentModel,
        orderId: any
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-documents/${orderId}`;

        return this.http
            .post<GeneralResponse>(url, documents.prepareForStore())
            .pipe(
                map((res) => {
                    return new GeneralResponse(
                        "",
                        false,
                        false,
                        OrderModel.createOne(res.data, new OrderModel())
                    );
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    editDocument(data: any, id: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-documents/${id}`;
        return this.http.put<GeneralResponse>(url, data).pipe(
            map((res) => {
                return new GeneralResponse("", false, false, res.data);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    updateOrderPercentage(
        newPercentage: number,
        orderId: number
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}update-percentage-order/${orderId}?XDEBUG_SESSION_START=PHPSTORM`;
        return this.http
            .post<GeneralResponse>(url, { percentage: newPercentage })
            .pipe(
                map((res) => {
                    return new GeneralResponse("", false, false, res.data);
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    getLastLocation(orderId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-locations/${orderId}`;

        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                if (!res.error) {
                    return new GeneralResponse(
                        "",
                        false,
                        false,
                        OrderLocationModel.createOne(
                            res.data,
                            new OrderLocationModel()
                        )
                    );
                }

                return new GeneralResponse(res.message, true, false, null);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getInternationalCount(): Observable<{} | GeneralResponse> {
        const url = `${this._api}get-international-order-count`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse("", false, false, res.data);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getNationalCount(): Observable<{} | GeneralResponse> {
        const url = `${this._api}get-national-order-count`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse("", false, false, res.data);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getOrdersCountByStatus(): Observable<{} | GeneralResponse> {
        const url = `${this._api}get-orders-by-status-count`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse("", false, false, res.data);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getOrdersImgDocNoApproved(): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders-img-doc-no-approved`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse(
                    "",
                    false,
                    false,
                    OrderModel.createArray(res.data, new OrderModel())
                );
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    storeOrderLoad(order: OrderLoadModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders-load`;
        return this.http
            .post<GeneralResponse>(url, order.prepareForStore())
            .pipe(
                map((res) => {
                    if (res.error) {
                        return new GeneralResponse(
                            res.message,
                            true,
                            false,
                            ""
                        );
                    } else {
                        return new GeneralResponse(
                            "",
                            false,
                            false,
                            OrderLoadModel.createOne(
                                res.data,
                                new OrderLoadModel()
                            )
                        );
                    }
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    getOneOrdeLoad(orderId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders-load/${orderId}?include=order,order.crts,trucker,trucker.trucker,trucker.trucker.truck,trucker.trucker.truck.trailer,countryFrom,countryTo,users,contract,orderUnload`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse(
                    "",
                    false,
                    false,
                    OrderLoadModel.createOne(res.data, new OrderLoadModel())
                );
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    updateOrdeLoad(order: OrderLoadModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders-load/${order.id}`;
        return this.http
            .put<GeneralResponse>(url, order.prepareForStore())
            .pipe(
                map((res) => {
                    if (res.error) {
                        return new GeneralResponse(
                            res.message,
                            true,
                            false,
                            ""
                        );
                    } else {
                        return new GeneralResponse(
                            "",
                            false,
                            false,
                            OrderLoadModel.createOne(
                                res.data,
                                new OrderLoadModel()
                            )
                        );
                    }
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    storeOrderUnload(
        orderUnload: OrderUnloadModel
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders-unload`;
        return this.http
            .post<GeneralResponse>(url, orderUnload.prepareForStore())
            .pipe(
                map((res) => {
                    if (res.error) {
                        return new GeneralResponse(
                            res.message,
                            true,
                            false,
                            ""
                        );
                    } else {
                        return new GeneralResponse(
                            "",
                            false,
                            false,
                            OrderUnloadModel.createOne(
                                res.data,
                                new OrderUnloadModel()
                            )
                        );
                    }
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    updateOrderUnload(
        orderUnload: OrderUnloadModel
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders-unload/${orderUnload.id}`;
        return this.http
            .put<GeneralResponse>(url, orderUnload.prepareForStore())
            .pipe(
                map((res) => {
                    if (res.error) {
                        return new GeneralResponse(
                            res.message,
                            true,
                            false,
                            ""
                        );
                    } else {
                        return new GeneralResponse(
                            "",
                            false,
                            false,
                            OrderUnloadModel.createOne(
                                res.data,
                                new OrderUnloadModel()
                            )
                        );
                    }
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    saveProblem(data, orderId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-problem/${orderId}`;
        return this.http.post<GeneralResponse>(url, data).pipe(
            map((res) => {
                if (res.error) {
                    return new GeneralResponse(res.message, true, false, "");
                } else {
                    return new GeneralResponse(res.message, false, false, "");
                }
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getOrderPDF(orderId: Number): Observable<{} | GeneralResponse> {
        const url = `${this._api}download-order-load/${orderId}`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                if (res.error) {
                    return new GeneralResponse(res.message, true, false, "");
                } else {
                    return new GeneralResponse(
                        "Se ha generado la Orden de Carga exitosamente!",
                        false,
                        false,
                        ReportModel.createOne(res.data, new ReportModel())
                    );
                }
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getOrderUnloadPDF(orderId: Number): Observable<{} | GeneralResponse> {
        const url = `${this._api}download-order-unload/${orderId}`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                if (res.error) {
                    return new GeneralResponse(res.message, true, false, "");
                } else {
                    return new GeneralResponse(
                        "Se ha generado la Orden de Carga exitosamente!",
                        false,
                        false,
                        ReportModel.createOne(res.data, new ReportModel())
                    );
                }
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getOrderDetailPDF(orderId: Number): Observable<{} | GeneralResponse> {
        const url = `${this._api}download-order-detail/${orderId}`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                if (res.error) {
                    return new GeneralResponse(res.message, true, false, "");
                } else {
                    return new GeneralResponse(
                        "Se ha generado el detalle de la Orden exitosamente!",
                        false,
                        false,
                        ReportModel.createOne(res.data, new ReportModel())
                    );
                }
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getOrderReceptionPDF(orderId: Number): Observable<{} | GeneralResponse> {
        const url = `${this._api}download-order-reception/${orderId}`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                if (res.error) {
                    return new GeneralResponse(res.message, true, false, "");
                } else {
                    return new GeneralResponse(
                        "Se ha generado la Orden de Carga exitosamente!",
                        false,
                        false,
                        ReportModel.createOne(res.data, new ReportModel())
                    );
                }
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getOrderLoads(page, size): Observable<{} | GeneralResponse> {
        return this.getOrderLoadsWithFilters(
            page,
            size,
            this.filterService.formatForURL(this._filters)
        );
    }

    getOrderLoadsWithFilters(
        page,
        size,
        filters: string
    ): Observable<{} | GeneralResponse> {
        const url = `${
            this._api
        }orders-load?include=order.crts,trucker,truckerCount,trucker.trucker,trucker.trucker.truck,contract,orderUnload&${filters}page[number]=${
            page + 1
        }&page[size]=${size}`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                const data = OrderLoadModel.createArray(
                    res.data,
                    new OrderLoadModel()
                );
                return new GeneralResponse(
                    "",
                    false,
                    false,
                    data,
                    res.links,
                    res.meta
                );
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getOneOrdeUnload(orderId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}orders-unload/${orderId}?include=trucker,trucker.trucker,trucker.trucker.truck,orderLoad,orderLoad.order,orderImage`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse(
                    "",
                    false,
                    false,
                    OrderUnloadModel.createOne(res.data, new OrderUnloadModel())
                );
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getOneOrderReception(orderId: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-receptions/${orderId}?include=order,orderImage`;
        return this.http.get<GeneralResponse>(url).pipe(
            map((res) => {
                return new GeneralResponse(
                    "",
                    false,
                    false,
                    OrderReceptionModel.createOne(
                        res.data,
                        new OrderReceptionModel()
                    )
                );
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    storeOrderReception(
        order: OrderReceptionModel
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-receptions`;
        return this.http
            .post<GeneralResponse>(url, order.prepareForStore())
            .pipe(
                map((res) => {
                    if (res.error) {
                        return new GeneralResponse(
                            res.message,
                            true,
                            false,
                            ""
                        );
                    } else {
                        return new GeneralResponse(
                            "",
                            false,
                            false,
                            OrderReceptionModel.createOne(
                                res.data,
                                new OrderReceptionModel()
                            )
                        );
                    }
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    updateOrdeReception(
        order: OrderReceptionModel
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-receptions/${order.id}`;
        return this.http
            .put<GeneralResponse>(url, order.prepareForStore())
            .pipe(
                map((res) => {
                    if (res.error) {
                        return new GeneralResponse(
                            res.message,
                            true,
                            false,
                            ""
                        );
                    } else {
                        return new GeneralResponse(
                            "",
                            false,
                            false,
                            OrderReceptionModel.createOne(
                                res.data,
                                new OrderReceptionModel()
                            )
                        );
                    }
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    changeStatusHasTruck(
        data,
        orderId: number
    ): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-has-truck/${orderId}`;
        return this.http.post<GeneralResponse>(url, data).pipe(
            map((res) => {
                if (res.error) {
                    return new GeneralResponse(res.message, true, false, "");
                } else {
                    return new GeneralResponse(res.message, false, false, "");
                }
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    onSaveCross(data, orderId: number): Observable<{} | GeneralResponse> {
        debugger;
        const url = `${this._api}order-crossing-request/${orderId}`;
        return this.http.post<GeneralResponse>(url, data).pipe(
            map((res) => {
                if (res.error) {
                    return new GeneralResponse(res.message, true, false, "");
                } else {
                    return new GeneralResponse(res.message, false, false, "");
                }
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    getCheckTruck(): Observable<{} | GeneralResponse> {
        const url = `${this._api}check-trucks`;
        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                return new GeneralResponse(
                    '',
                    false,
                    false,
                    res.data
                );
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    getOneCheckTruck(checkTruck :ChecklistTruckModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}check-trucks/${checkTruck.id}?include=checklistPoints,trucker,truck`;
        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                return new GeneralResponse(
                    '',
                    false,
                    false,
                    res.data
                );
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    getChecklist(): Observable<{} | GeneralResponse> {
        const url = `${this._api}checklist-trucks`;
        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                return new GeneralResponse(
                    '',
                    false,
                    false,
                    res.data
                );
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    storeCheckTruck(checkTruck: ChecklistTruckModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}check-trucks`;
        return this.http
            .post<GeneralResponse>(url, checkTruck.prepareForStore())
            .pipe(
                map(res => {
                    if (res.error) {
                        return new GeneralResponse(
                            res.message,
                            true,
                            false,
                            ''
                        );
                    } else {
                        return new GeneralResponse(
                            '',
                            false,
                            false,
                            ChecklistTruckModel.createOne(res.data, new ChecklistTruckModel())
                        );
                    }
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }

    updateCheckTruck(checkTruck: ChecklistTruckModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}check-trucks/${checkTruck.id}`;
        return this.http
            .put<GeneralResponse>(url, checkTruck.prepareForStore())
            .pipe(
                map(res => {
                    if (res.error) {
                        return new GeneralResponse(
                            res.message,
                            true,
                            false,
                            ''
                        );
                    } else {
                        return new GeneralResponse(
                            '',
                            false,
                            false,
                            ChecklistTruckModel.createOne(res.data, new ChecklistTruckModel())
                        );
                    }
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    chekTruckDownload(checkTruck: ChecklistTruckModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}download-checklist-truck/${checkTruck.id}`;
        console.log(url)
        return this.http
            .get<GeneralResponse>(url)
            .pipe(
                map(res => {
                    if (res.error) {
                        return new GeneralResponse(
                            res.message,
                            true,
                            false,
                            ''
                        );
                    } else {
                        return new GeneralResponse('Se ha generado el cheklist exitosamente!', false, false, ChecklistTruckModel.createOne(res.data, new ChecklistTruckModel()));
                    }
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }

    deleteCross(order: OrderModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}order-crossing-request/${order.id}`;
        return this.http
            .delete<GeneralResponse>(url)
            .pipe(
                map((res) => {
                    console.log('respuesta en service', res)
                    return new GeneralResponse(res.message);
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            )

    }

    setCompanyControlState(value: string): void {
        this.companyControlState = value;
    }
    
    getCompanyControlState(): string {
        return this.companyControlState;
    }
}
