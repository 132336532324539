import { Serializable } from "app/core/entities/serializable.model";
import { CompanyModel } from "./company.model";

export class TariffModel extends Serializable {
    public id: number;
    public route: string;
    public transportCost: string;
    public secure: string;
    public totalCost: string;
    public paymentMethod: string;
    public coin: string;
    public responsibleForPayment: string;
    public detail: string;
    public checked: boolean;
    public costsTaken:string;
    public company_id: number;
    public companies: CompanyModel[];

    newModel(data: any): TariffModel {
        const dataParsed = new TariffModel(data);

        if (data.companies) {
            data.companies = CompanyModel.createOne(
                data.companies,
                new CompanyModel()
            );
        } else {
            data.companies = [];
        }

        return dataParsed;
    }

    prepareForStore(): any {
        return {
            route: this.route,
            transport_cost: this.transportCost,
            secure: this.secure,
            total_cost: this.totalCost,
            payment_method: this.paymentMethod,
            coin: this.coin,
            responsible_for_payment: this.responsibleForPayment,
            detail: this.detail,
            cost_taken:this.costsTaken,
            companies_id: this.company_id ? [this.company_id] : null,
        };
    }
}
