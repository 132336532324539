import {Serializable} from '../../core/entities/serializable.model';

export class Auth extends Serializable{

    protected _SUPERADMIN = 'superadmin';
    protected _ADMIN = 'admin';

    public id: number;
    public name: string;
    public lastName: string;
    public fullName: string;
    public email: string;
    public token: string;
    public tokenType: string;
    public role: string;
    public language: any;
    public password: string;
    public password_confirmation: string;

    newModel(data: any): Auth {
        return new Auth(data);
    }


    get isSuperadmin(): boolean {
        return this.role === this._SUPERADMIN;
    }
}
