import { Serializable } from "app/core/entities/serializable.model";
import { TruckerModel } from "app/shared/models/trucker.model";
import { OrderLoadModel } from "./order-load.model";
import { Auth } from "app/shared/models/auth.model";
import { OrderImageModel } from "./order-image.model";


export class OrderUnloadModel extends Serializable {
    public id: number;
    public orderLoadId: string;
    public truckerId: string;
    public truckerName: string;
    public truckerDni: string;
    public truckerPlate: string;
    public sender: string;
    public receiver: string;
    public manager: string;
    public addressFrom: string;
    public addressTo: string;
    public crtNumber: string;
    public orderNumber: string;
    public offer: string;
    public quantity: string;
    public weight: string;
    public volume: string;
    public observations: string;
    public orderUnloadDate: string;
    public hasProblem: number;
    public problemDescription: string;
    public trucker: TruckerModel;
    public orderLoad: OrderLoadModel;
    public orderImage: OrderImageModel[];


    newModel(data: any): OrderUnloadModel {
        const dataParsed = new OrderUnloadModel(data);
        if (data.trucker) {
            dataParsed.trucker = TruckerModel.createOne(data.trucker, new Auth());
        }

        if (data.orderLoad) {
            dataParsed.orderLoad = OrderLoadModel.createOne(data.orderLoad, new OrderLoadModel());
        }

        if (data.orderImage) {
            dataParsed.orderImage = OrderImageModel.createArray(data.orderImage, new OrderImageModel());
        }

        return new OrderUnloadModel(dataParsed);
    }

    prepareForStore(): any {
        let data = {
            id: this.id ?? null,
            order_load_id: this.orderLoad ? this.orderLoad.id : null,
            trucker_id: this.trucker ? this.trucker.user ? this.trucker.user.id : this.trucker.id : null,
            trucker_name: this.truckerName,
            trucker_dni: this.truckerDni,
            trucker_plate: this.truckerPlate,
            sender: this.sender,
            receiver: this.receiver,
            manager: this.manager,
            address_to: this.addressTo,
            address_from: this.addressFrom,
            crt_number: this.crtNumber,
            order_number: this.orderNumber,
            offer: this.offer,
            quantity: this.quantity,
            weight: this.weight,
            volume: this.volume,
            observations: this.observations,
            order_unload_date: this.orderUnloadDate,
            has_problem: this.hasProblem,
        };

        data['problem_description'] = this.problemDescription;
        if (this.orderImage != null && this.orderImage.length > 0) {
            var images = [];
            this.orderImage.forEach(image => {
                images.push(image.prepareForStoreOrderUnload());
            });
            data['images'] = images;
        } else {
            data['images'] = [];
        }

        return data;
    }

}