import {Injectable} from '@angular/core';
import {FilterModel} from '../models/filter.model';

@Injectable({providedIn: 'root'})
export class FilterService {

    formatForURL(filters: FilterModel[]): string {
        let uriFilter = '';
        for (const filter of filters) {
            if (filter.isArray) {
                uriFilter += (filter.value.length > 0) ? this.filterFormat(filter.field, filter.value) : '';
            } else {
                uriFilter += (filter.value !== '') ? this.filterFormat(filter.field, filter.value) : '';
            }
        }
        return uriFilter;
    }

    filterFormat(field: string, value: any): string {
        return `filter[${field}]=${value}&`;
    }

    /**
     * Return true is at least 1 filter is on
     */
    isFilterOn(filters: FilterModel[]): boolean {
        for (const filter of filters) {
            if (filter.isArray) {
                if (filter.value.length > 0) { return true; }
            } else {
                if (filter.value !== '') { return true; }
            }
        }
        return false;
    }

    writeStorage(tag: string, filters: FilterModel[]): void {
        localStorage.setItem('filter_' + tag, JSON.stringify(filters));
    }

    readStorage(tag: string): any {
        return JSON.parse(localStorage.getItem('filter_' + tag));
    }
}
