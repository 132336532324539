import { Serializable } from "app/core/entities/serializable.model";


import { CrtClientModel } from "./crt-client.model";
import { MicModel } from "./mic.model";

export class MicDataModel extends Serializable {
    public id: number;
    public micId: number;
    public sheetNumber: number;
    public issueDate:string;   
    public crtNumber: string;
    public customDestination: string;
    public customDestinationCode: string;
    public coin: string;
    public fromLocation: string;
    public fromLocationCode: string;
    public fotValue: number;
    public cargoValue: number;
    public insuranceValue: number;
    public volumenType: string;
    public volumenCode: string;
    public quantity: string;
    public grossWeight: string;
    public senderId: number;
    public receiverId: number;
    public consigneeId: number;
    public attachedBill: string;
    public attachedDue: string;
    public attachedPo: string;
    public attachedNf: string;
    public attachedExtra: string;
    public sealNumber: string;
    public orderDescription: string;

    public mic: MicModel;
    public sender: CrtClientModel;
    public receiver: CrtClientModel;
    public consignee: CrtClientModel;

    newModel(data: any): MicDataModel {
        const dataParsed = new MicDataModel(data);
        if (data.sender) {
            dataParsed.sender = CrtClientModel.createOne(
                data.sender,
                new CrtClientModel()
            );
        }
        if (data.sender) {
            dataParsed.receiver = CrtClientModel.createOne(
                data.receiver,
                new CrtClientModel()
            );
        }
        if (data.consignee) {
            dataParsed.consignee = CrtClientModel.createOne(
                data.consignee,
                new CrtClientModel()
            );
        }
        if (data.mic) {
            dataParsed.mic = MicModel.createOne(
                data.mic,
                new MicModel()
            );
        }

        return new MicDataModel(dataParsed);
    }

    prepareForStore(): any {
        return {
            id: this.id ?? null,
            mic_id: this.micId,
            sheet_number: this.sheetNumber,
            issue_date:this.issueDate,
            crt_number: this.crtNumber,
            custom_destination: this.customDestination,
            custom_destination_code: this.customDestinationCode,
            coin: this.coin,
            from_location: this.fromLocation,
            from_location_code: this.fromLocationCode,
            fot_value: this.fotValue,
            cargo_value: this.cargoValue,
            insurance_value: this.insuranceValue,
            volumen_type: this.volumenType,
            volumen_code: this.volumenCode,
            quantity: this.quantity,
            gross_weight: this.grossWeight,
            attached_bill: this.attachedBill,
            attached_due: this.attachedDue,
            attached_po: this.attachedPo,
            attached_nf: this.attachedNf,
            attached_extra: this.attachedExtra,
            seal_number: this.sealNumber,
            order_description: this.orderDescription,

            receiver_id: this.receiver != null ? this.receiver.id : null,
            consignee_id: this.consignee != null ? this.consignee.id : null,
            sender_id: this.sender != null ? this.sender.id : null,

            'sender': this.sender != null ? this.sender.prepareForStore() : null,
            'receiver' : this.receiver != null ? this.receiver.prepareForStore() : null,
            'consignee' : this.consignee != null ? this.consignee.prepareForStore() : null,
        };
    }
}
