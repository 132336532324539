import { Serializable } from "app/core/entities/serializable.model";
import { TruckerModel } from "app/shared/models/trucker.model";
import { OrderLoadModel } from "./order-load.model";
import { Auth } from "app/shared/models/auth.model";
import { OrderImageModel } from "./order-image.model";
import { TruckModel } from "app/shared/models/truck.model";
import { TrailerModel } from "app/shared/models/trailer.model";


export class ChecklistTruckModel extends Serializable {
    public id: number;
    public date: string;
    public inspectionPremises: string;
    public chargeType: string;
    public stampNumber: string;
    public stampQuantity: string;
    public stampIntegrity: string;
    public observations: string;
    public truckerName: string;
    public truckerPlate: string;
    public truckerDni: string;
    public truckerCuit: string;
    public companyName: string;
    public trailerId: number;
    public truckId: number;
    public plate03: boolean;
    public plate04: boolean;
    public state: boolean;
    public checklistPoints: [];
    public trucker: TruckerModel;
    public orderLoad: OrderLoadModel;
    public truck: TruckModel;


    newModel(data: any): ChecklistTruckModel {
        const dataParsed = new ChecklistTruckModel(data);
        if (data.trucker) {
            dataParsed.trucker = TruckerModel.createOne(data.trucker, new Auth());
        }

        if (data.orderLoad) {
            dataParsed.orderLoad = OrderLoadModel.createOne(data.orderLoad, new OrderLoadModel());
        }

        return new ChecklistTruckModel(dataParsed);
    }

    prepareForStore(): any {
        let data = {
            id: this.id ?? null,
            date: this.date,
            inspection_premises: this.inspectionPremises,
            charge_type: this.chargeType,
            stamp_number: this.stampNumber,
            stamp_quantity: this.stampQuantity,
            stamp_integrity: this.stampIntegrity,
            observations: this.observations,
            trucker_name: this.truckerName,
            trucker_plate: this.truckerPlate,
            trucker_dni: this.truckerDni,
            trucker_cuit: this.truckerCuit,
            company_name: this.companyName,
            truck_id: this.truckId,
            trailer_id: this.trailerId,
            plate_03: this.plate03,
            plate_04: this.plate04,
            state: this.state,
            checklist_points: this.checklistPoints,
            trucker_id: this.trucker ? this.trucker.id : null,
            order_load_id: this.orderLoad ? this.orderLoad.id : null
        };
        return data;
    }

}