import { Serializable } from "app/core/entities/serializable.model";

import { CrtClientModel } from './crt-client.model';
import { MicDataModel } from './micData.model';
import { MicTrunckInfoModel } from './micTrunckInfo.model';
import { CrtMicPermitModel } from './crt-mic-permit.model';
import { CrtModel } from './crt.model';
import { UserModel } from "app/shared/models/user.model";

export class MicModel extends Serializable {
    public id: number;
    public micNumber:string;
    public status:string;
    public customFrom: string;
    public customFromCode: string;
    public destination: string;
    public destinationCode: string;
    public creator: UserModel;
    public modifiedBy: UserModel;
    public creatorID:string;
    public modifiedByID:string;
    public crtMicPermitsId:number;
    public totalSheet:number;
    public rolCuit:string;
    public customsTransit:number;
    public truckInfoId:number;
    public substituteInfoId:number;
    public firmDate: string;
    public firmName: string;
    public customFirm: string;
    public routeInformation: string;
    public customFirmDate: string;

    public micDatas:MicDataModel[];
    public crt:CrtModel;
    public truckInfo:MicTrunckInfoModel;
    public substituteInfo:MicTrunckInfoModel;
    public crtMicPermits:CrtMicPermitModel;
    

    newModel(data: any): MicModel {
        const dataParsed = new MicModel(data);

        if (data.creator) {
            dataParsed.creator = UserModel.createOne(data.creator, new UserModel());
        }

        if (data.modifiedBy) {
            dataParsed.modifiedBy = UserModel.createOne(data.modifiedBy, new UserModel());
        }

        if (data.micDatas) {
            dataParsed.micDatas = MicDataModel.createArray(data.micDatas, new MicDataModel());
        }

        if (data.truckInfo) {
            dataParsed.truckInfo = MicTrunckInfoModel.createOne(data.truckInfo, new MicTrunckInfoModel());
        }

        if (data.substituteInfo) {
            dataParsed.substituteInfo = MicTrunckInfoModel.createOne(data.substituteInfo, new MicTrunckInfoModel());
        }

        if (data.crtMicPermits) {
            dataParsed.crtMicPermits = CrtMicPermitModel.createOne(data.crtMicPermits, new CrtMicPermitModel());
        }

        if(data.crt){
            dataParsed.crt=CrtModel.createOne(
                data.crt,
                new CrtModel()
            )
        }

        return new MicModel(dataParsed);
    }

    prepareForStore(): any {
        let data = {};
        data['mic'] = {
            id: this.id ?? null,
            mic_number: this.micNumber,
            status: this.status,
            custom_from: this.customFrom,
            custom_from_code: this.customFromCode,
            destination: this.destination,
            destination_code: this.destinationCode,
            creator_id: this.creator != null ? this.creator.id : Number(this.creatorID),
            modified_by: this.modifiedBy != null ? this.modifiedBy.id : Number(this.modifiedByID),
            crt_mic_permits_id: this.crtMicPermits != null ? this.crtMicPermits.id : null,
            total_sheet: this.totalSheet,
            rol_cuit:this.rolCuit,
            customs_transit:this.customsTransit,
            firm_date: this.firmDate,
            firm_name: this.firmName,
            custom_firm: this.customFirm,
            custom_firm_date: this.customFirmDate,
            route_information: this.routeInformation,

            truck_info_id: this.truckInfo != null ? this.truckInfo.id : null,     
            substitute_info_id: this.substituteInfo != null ? this.substituteInfo.id : null,         
        };
        data['micDatas'] = this.saveDatas(this.micDatas);
        data['permit'] = this.crtMicPermits.prepareForStore();        
        data['truckInfo'] = this.truckInfo != null ? this.truckInfo.prepareForStore() : null;
        data['substituteInfo'] = this.substituteInfo != null ? this.substituteInfo.prepareForStore() : null;

        return data;
    }

    saveDatas(datas: MicDataModel[]) {
        var dataToReturn = [];
        datas.forEach(element => { 
            dataToReturn.push(element.prepareForStore()); 
        });
        return dataToReturn;
    }
}