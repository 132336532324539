import {Serializable} from '../../../core/entities/serializable.model';

export class OrderCrossoverModel extends Serializable {
    public id: number;
    public orderId: number;
    public date: Date;
    public details: string;

    newModel(data: any): OrderCrossoverModel {
        return new OrderCrossoverModel(data);
    }
}
