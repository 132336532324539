import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

import {environment} from '../../../environments/environment';
import {of, throwError} from 'rxjs';
import {GeneralResponse} from '../../shared/models/general-response.model';
import {FilterModel} from '../../shared/models/filter.model';
import {ColumnConfigModel} from '../../shared/models/column-config.model';


@Injectable()
export class BaseService {

    public _api = environment.api;
    public _headers = new HttpHeaders();
    protected _filters: FilterModel[] = [];

    protected _columnSettings: ColumnConfigModel[] = [];

    constructor(public router: Router, filters: FilterModel[] = [], columnSettings: ColumnConfigModel[] = []) {
        this._headers.set('Accept', 'application/json')
            .append('Access-Control-Allow-Origin', '*')
            .append('Content-type', 'application/x-www-form-urlencoded')
            .append('X-Requested-With', 'XMLHttpRequest');

        this._filters = filters;
        this._columnSettings = columnSettings;
    }

    filterFormat(field: string, value: any): string {
        return `filter[${field}]=${value}&`;
    }

    public handleError(error: HttpErrorResponse | any): any {
        
        const response = new GeneralResponse();
        response.data = [];
        response.error = true;

        if (error.error.httpCode === 401) {
            response.message = 'La sesión esta finalizada. Ingrese sus credenciales nuevamente.';
            this.router.navigate(['/login']);
        }

        if (error.error.data) {
            response.message = error.error.data.message;

            if (error.error.data.httpCode === 401) {
                this.router.navigate(['/login']);
            }

            if (error.error.data.errors) {

                const arrayErrors = Object.keys(error.error.data.errors).map(i => error.error.data.errors[i]);
                for (const err of arrayErrors) {
                    response.data.push(err);
                }
            }
        }


        if (response.message === '') {
            response.message = 'Ocurrio un problema con la comunicación con el servidor. Verfique su conexion y vuelva a intentarlo.';
        }
        return throwError(response);
    }

    // ***** filters
    get filters(): FilterModel[] {
        return this._filters;
    }

    set filters(filters: FilterModel[]) {
        this._filters = filters;
    }

    cleanFilters(): void {
        for (const filter of this._filters) {
            if (filter.isArray) {
                filter.value = [];
            } else {
                filter.value = '';
            }
        }
    }

    // -----

    // columns setting
    get columnSettings(): ColumnConfigModel[] {
        return this._columnSettings;
    }

    set columnSettings(columnSettings: ColumnConfigModel[]) {
        this._columnSettings = columnSettings;
    }

}
