import { Serializable } from '../../../core/entities/serializable.model';
import { UserModel } from 'app/shared/models/user.model';
import { OrderModel } from 'app/main/orders/models/order.model';
import { CrtMicPermitModel } from './crt-mic-permit.model';
import { CrtClientModel } from './crt-client.model';
import { CrtExpenseModel } from './crt-expense.model';
import { MicModel } from './mic.model';

export class CrtModel extends Serializable {
    public id: number;
    public crt_number: string;
    public status: string;
    public creator: UserModel;
    public modifiedBy: UserModel;
    public creatorID: string;
    public modifiedByID: string;
    public sender: CrtClientModel;
    public orders: OrderModel[];
    public crtMicPermits: CrtMicPermitModel;
    public receiver: CrtClientModel;
    public sender_location: string;
    public consignee: CrtClientModel;
    public transporter_location: string;
    public transporter_date: string;
    public receiver_location: string;
    public receiver_date: string;
    public notify: CrtClientModel;
    public transporter_location_extra: string;
    public order_description: string;
    public volumenType: string;
    public volumenCode: string;
    public quantity: string;
    public gross_weight: string;
    public net_weight: string;
    public volume: string;
    public value: number;
    public coin_value: string;
    public inco_term: string;
    public mercancy_value: number;
    public mercancy_coin: string;
    public attached_bill: string;
    public attached_due: string;
    public attached_po: string;
    public attached_nf: string;
    public attached_extra: string;
    public instructions: string;
    public external_cargo_value: number;
    public external_cargo_coin: string;
    public refund_value: number;
    public refund_coin: string;
    public sender_firm: string;
    public sender_firm_date: string;
    public observations: string;
    public transporter_firm: string;
    public transporter_firm_date: string;
    public receiver_firm: string;
    public receiver_firm_date: string;

    public crtExpenses: CrtExpenseModel[];
    public mics: MicModel[];

    newModel(data: any): CrtModel {
        const dataParsed = new CrtModel(data);

        if (data.creator) {
            dataParsed.creator = UserModel.createOne(data.creator, new UserModel());
        }

        if (data.modifiedBy) {
            dataParsed.modifiedBy = UserModel.createOne(data.modifiedBy, new UserModel());
        }

        if (data.sender) {
            dataParsed.sender = CrtClientModel.createOne(data.sender, new CrtClientModel());
        }

        if (data.consignee) {
            dataParsed.consignee = CrtClientModel.createOne(data.consignee, new CrtClientModel());
        }

        if (data.notify) {
            dataParsed.notify = CrtClientModel.createOne(data.notify, new CrtClientModel());
        }

        if (data.receiver) {
            dataParsed.receiver = CrtClientModel.createOne(data.receiver, new CrtClientModel());
        }

        if (data.crtMicPermits) {
            dataParsed.crtMicPermits = CrtMicPermitModel.createOne(data.crtMicPermits, new CrtMicPermitModel());
        }

        if (data.orders) {
            dataParsed.orders = OrderModel.createArray(data.orders, new OrderModel());
        }

        if (data.crtExpenses) {
            dataParsed.crtExpenses = CrtExpenseModel.createArray(data.crtExpenses, new CrtExpenseModel());
        }

        if (data.mics) {
            dataParsed.mics = MicModel.createArray(data.mics, new MicModel());
        }

        return new CrtModel(dataParsed);
    }

    get statusName(): string {
        switch (this.status) {
            case 'assigned':
                return 'Asignado';
            case 'unassigned':
                return 'Desasignado';
            case 'canceled':
                return 'Cancelado';
        }
    }

    get nextStatus(): string[] {
        switch (this.status) {
            case 'assigned':
                return ['canceled'];
            case 'unassigned':
                return ['assigned', 'canceled'];
            case 'canceled':
                return [];
        }
    }

    prepareForStore(): any {
        let data = {};
        data['crt'] = {
            crt_number: this.crt_number,
            status: this.status,
            creator_id: this.creator != null ? this.creator.id : Number(this.creatorID),
            modified_by: this.modifiedBy != null ? this.modifiedBy.id : Number(this.modifiedByID),
            sender_id: this.sender != null ? this.sender.id : null,
            crt_mic_permits_id: this.crtMicPermits != null ? this.crtMicPermits.id : null,

            receiver_id: this.receiver != null ? this.receiver.id : null,
            consignee_id: this.consignee != null ? this.consignee.id : null,
            notify_id: this.notify != null ? this.notify.id : null,

            sender_location: this.sender_location,
            transporter_location: this.transporter_location,
            transporter_date: this.transporter_date,
            receiver_location: this.receiver_location,
            receiver_date: this.receiver_date,
            transporter_location_extra: this.transporter_location_extra,
            order_description: this.order_description,
            volumen_type: this.volumenType,
            volumen_code: this.volumenCode,
            quantity: this.quantity,
            gross_weight: this.gross_weight,
            net_weight: this.net_weight,
            volume: this.volume,
            value: this.value,
            coin_value: this.coin_value,
            inco_term: this.inco_term,
            mercancy_value: this.mercancy_value,
            mercancy_coin: this.mercancy_coin,
            attached_bill: this.attached_bill,
            attached_due: this.attached_due,
            attached_po: this.attached_po,
            attached_nf: this.attached_nf,
            attached_extra: this.attached_extra,
            instructions: this.instructions,
            external_cargo_value: this.external_cargo_value,
            external_cargo_coin: this.external_cargo_coin,
            refund_value: this.refund_value,
            refund_coin: this.refund_coin,
            sender_firm: this.sender_firm,
            sender_firm_date: this.sender_firm_date,
            observations: this.observations,
            transporter_firm: this.transporter_firm,
            transporter_firm_date: this.transporter_firm_date,
            receiver_firm: this.receiver_firm,
            receiver_firm_date: this.receiver_firm_date,
        };

        data['sender'] = this.sender.prepareForStore();
        data['receiver'] = this.receiver.prepareForStore();
        data['consignee'] = this.consignee.prepareForStore();
        data['notify'] = this.notify.prepareForStore();
        data['permit'] = this.crtMicPermits.prepareForStore();
        data['expenses'] = this.saveEpenses(this.crtExpenses);


        return data;
    }

    saveEpenses(expenses: CrtExpenseModel[]) {
        var expensesToReturn = [];
        expenses.forEach(element => { expensesToReturn.push(element.prepareForStore()); });
        return expensesToReturn;
    }

    static createEmpty(): CrtModel {
        const crt = new CrtModel();

        crt.crt_number = "";
        crt.status = "";
        crt.creator = new UserModel();
        crt.modifiedBy = new UserModel();
        crt.creatorID = "";
        crt.modifiedByID = "";
        crt.sender = CrtClientModel.createEmpty();
        crt.orders = [];
        crt.crtMicPermits = CrtMicPermitModel.createEmpty();
        crt.receiver = CrtClientModel.createEmpty();
        crt.sender_location = "";
        crt.consignee = CrtClientModel.createEmpty();
        crt.transporter_location = "";
        crt.transporter_date = "";
        crt.receiver_location = "";
        crt.receiver_date = "";
        crt.notify = CrtClientModel.createEmpty();
        crt.transporter_location_extra = "";
        crt.order_description = "";
        crt.gross_weight = "";
        crt.net_weight = "";
        crt.volume = "";
        crt.value = 0;
        crt.coin_value = "";
        crt.mercancy_value = 0;
        crt.mercancy_coin = "";
        crt.attached_bill = "";
        crt.attached_due = "";
        crt.attached_po = "";
        crt.attached_nf = "";
        crt.attached_extra = "";
        crt.instructions = "";
        crt.external_cargo_value = 0;
        crt.external_cargo_coin = "";
        crt.refund_value = 0;
        crt.refund_coin = "";
        crt.sender_firm = "";
        crt.sender_firm_date = "";
        crt.observations = "";
        crt.transporter_firm = "";
        crt.transporter_firm_date = "";
        crt.receiver_firm = "";
        crt.receiver_firm_date = "";

        return crt;
    }
}