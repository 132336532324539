import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translatePaymentMethod'
})
export class TranslatePaymentMethodPipe implements PipeTransform {
  transform(value: string): string {
    const translations: { [key: string]: string } = {
      'tarjeta': 'Tarjeta de crédito',
      'cash': 'Efectivo',
      'transfer': 'Transferencia',
      'check': 'Cheque',
      'other': 'Otros'
    };

    return translations[value] || value; 
  }
}
