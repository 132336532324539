import {Injectable} from '@angular/core';
import {BaseService} from '../core/services/base.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Auth} from '../shared/models/auth.model';
import {Login} from './login.model';
import { Observable } from 'rxjs';
import {GeneralResponse} from '../shared/models/general-response.model';
import {catchError, map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService {
    private auth: Auth;
    

    constructor(public router: Router, public http: HttpClient) {
        super(router);

        // si esta logedo leavanto el objeto auth
        if (this.loggedIn) {
            this.auth = new Auth();
            this.auth.id = +localStorage.getItem('id');
            this.auth.name = localStorage.getItem('name');
            this.auth.lastName = localStorage.getItem('lastName');
            this.auth.fullName = localStorage.getItem('fullName');
            this.auth.email = localStorage.getItem('email');
            this.auth.token = localStorage.getItem('token');
            this.auth.tokenType = localStorage.getItem('tokenType');
            this.auth.role = localStorage.getItem('role');
        }
    }

    login(login: Login): Observable<{} | GeneralResponse> {
        const url = `${this._api}login`;
        return this.http.post<GeneralResponse>(url, login)
            .pipe(
                map(res => {
                    this.auth = new Auth(res.data);
                    this.loginWriteStorage();
                   
                    return new GeneralResponse('', false, false, this.auth);
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }

    logout(): void {
        this.http.post<GeneralResponse>(`${this._api}logout`, null)
            .pipe(
                map((response) => {
                    return new GeneralResponse('', false, false, response);
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            )
            .subscribe(
                () => {
                    localStorage.clear();
                    location.reload();
                });
    }

    loginWriteStorage(): void {
        localStorage.setItem('id', String(this.auth.id));
        localStorage.setItem('name', this.auth.name);
        localStorage.setItem('lastName', this.auth.lastName);
        localStorage.setItem('fullName', this.auth.fullName);
        localStorage.setItem('email', this.auth.email);
        localStorage.setItem('token', this.auth.token);
        localStorage.setItem('role', this.auth.role);
        localStorage.setItem('tokenType', this.auth.tokenType);
    }

    getToken(): string {
        const token = this.getItem('token');
        if (token) {
            return `${this.getItem('tokenType')} ${token}`;
        } else {
            return null;
        }
    }

    loggedIn(): boolean {
        return this.getToken() !== null;
    }

    private getItem(name: string): string {
        return localStorage.getItem(name);
    }

    get currentAuth(): Auth {
        return this.auth;
    }

    get fullName(): string {
        return this.getItem('fullName');
    }

    get ID(): string{
        return this.getItem('id');
    }


      
}
