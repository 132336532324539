import { Serializable } from "app/core/entities/serializable.model";

export class OrderPriceListModel  extends Serializable {
    public id:number;
    public orderId:number;
    public route: string;
    public transportCost: string;
    public secure: string;
    public totalCost: string;
    public paymentMethod: string;
    public coin: string;
    public responsibleForPayment: string;
    public detail: string;
    public dailyChange:number;

    newModel(data: any): OrderPriceListModel  {
        return new OrderPriceListModel (data);
    }

    prepareForStore(): any {

        
        return {
            id:this.id,
            route: this.route,
            transport_cost: this.transportCost,
            secure: this.secure,
            total_cost: this.totalCost,
            payment_method: this.paymentMethod,
            coin: this.coin,
            responsible_for_payment: this.responsibleForPayment,
            detail: this.detail,
            order_id:this.orderId,
            daily_change:this.dailyChange,
            
        };
    }
}
