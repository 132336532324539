import {Serializable} from '../../../core/entities/serializable.model';

export class OrderDocumentModel extends Serializable {
    public id: number;
    public description: string;
    public documentName: string;
    public orderId: number;
    public url: string;
    public file: any;
    public approved: number;
    public userApprovedId: string;
    public typeFile: string;

    newModel(data: any): OrderDocumentModel {
        return new OrderDocumentModel(data);
    }

    get isPdf(): boolean{
        return this.typeFile  === 'application/pdf';
    }

    prepareForStore(): any {
        const data = {
            documents: []
        };
        // fixme por si mas adelante quiren subir mas archivos
        data.documents.push({
            file: this.file,
            description: this.description,
            type_file: this.typeFile
        });
        return data;
    }
}
