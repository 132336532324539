import { Serializable } from "app/core/entities/serializable.model";


export class MicTrunckInfoModel extends Serializable {
        public id: number;
        public truckerCompanyName:string;
        public truckerCompanyAddress:string;
        public truckerCompanyCityCountry:string;
        public truckerCompanyPermissionName:string;
        public truckerCompanyPermissionAddress:string;
        public truckerCompanyPermissionCityCountry:string;
        public licenceNumber:string;
        public licenceDueDate:string;
        public licencePaut:string;
        public complementaryLicenceNumber:string;
        public complementaryDueDate:string;
        public insuranceNumber:string;
        public insuranceDueDate:string;
        public roleCuit:string;
        public truckPlateNumber:string;
        public truckBrand:string;
        public chassisNumber:string;
        public truckTara:string;
        public truckYear:string;
        public trailerPlateNumber:string;
        public isTrailer:number;
        public isSemitrailer:number;
        public trailerTara:string;
        public truckerName:string;
        public truckerDni:string;
        public truckerExtraInfo:string;

    newModel(data: any): MicTrunckInfoModel {
        return new MicTrunckInfoModel(data);
    }


    prepareForStore(): any {
        return {
            id: this.id ?? null,
            trucker_company_name:this.truckerCompanyName,
            trucker_company_address:this.truckerCompanyAddress,
            trucker_company_city_country:this.truckerCompanyCityCountry,
            trucker_company_permission_name:this.truckerCompanyPermissionName,
            trucker_company_permission_address:this.truckerCompanyPermissionAddress,
            trucker_company_permission_city_country:this.truckerCompanyPermissionCityCountry,
            licence_number:this.licenceNumber,
            licence_due_date:this.licenceDueDate,
            licence_paut:this.licencePaut,
            complementary_licence_number:this.complementaryLicenceNumber,
            complementary_due_date:this.complementaryDueDate,
            insurance_number:this.insuranceNumber,
            insurance_due_date:this.insuranceDueDate,
            role_cuit:this.roleCuit,
            truck_plate_number:this.truckPlateNumber,
            truck_brand:this.truckBrand,
            chassis_number:this.chassisNumber,
            truck_tara:this.truckTara,
            truck_year:this.truckYear,
            trailer_plate_number:this.trailerPlateNumber,
            is_trailer:this.isTrailer,
            is_semitrailer:this.isSemitrailer,
            trailer_tara:this.trailerTara,
            trucker_name:this.truckerName,
            trucker_dni:this.truckerDni,
            trucker_extra_info:this.truckerExtraInfo
        }}
}