<div fxFlex="100" fxLayout="column">
    <h1>{{title}}</h1>
    <div>{{message}}</div>
    <div class="pt-24" fxFlex="100"  fxLayoutAlign="space-around center">
        <button mat-raised-button [color]="s" *ngFor="let s of status" (click)="dialogRef.close(s)">
          {{nextStatusNameAction(s)}}
        </button>
        <button mat-raised-button color="primary" (click)="dialogRef.close(false)">{{cancelButton}}</button>
    </div>
</div>
