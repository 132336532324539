import {Injectable} from '@angular/core';
import {BaseService} from 'app/core/services/base.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {CountryService} from './country.service';
import {Observable, Subject} from 'rxjs';
import {GeneralResponse} from '../models/general-response.model';
import {CompanyModel} from '../models/company.model';
import {map, catchError} from 'rxjs/operators';
import {LanguageModel} from '../models/language.model';
import {CountryModel} from '../models/country.model';
import { Cacheable, CacheBuster } from 'ts-cacheable';

const cacheBuster$ = new Subject<void>();

@Injectable({
    providedIn: 'root'
})
export class CommonDataService extends BaseService {

    pageReady = new Subject<boolean>();
    private countryModel: CountryModel[] = [];
    private languageModel: LanguageModel[] = [];

    constructor(
        public router: Router,
        public http: HttpClient,
        private countryService: CountryService
    ) {
        super(router);
    }


    get countries(): CountryModel[] {
        return this.countryModel;
    }

    get languages(): LanguageModel[] {
        return this.languageModel;
    }

    public get(): Observable<{} | GeneralResponse> {
        const url = `${this._api}data/common-data`;
        return this.http.get<GeneralResponse>(url)
            .pipe(
                map(res => {
                    this.countryModel = CountryModel.createArray(res.data.countries, new CountryModel());
                    this.languageModel = LanguageModel.createArray(res.data.languages, new LanguageModel());
                    this.pageReady.next(true);
                    return new GeneralResponse('', false, false, null);
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$
    })
    getCompanies(): Observable<{} | GeneralResponse> {
        const url = `${this._api}data/companies`;
        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                const data = CompanyModel.createArray(
                    res.data,
                    new CompanyModel()
                );
                return new GeneralResponse('', false, false, data);
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$
    })
    getLanguages(): Observable<{} | GeneralResponse> {
        const url = `${this._api}data/languages`;
        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                const data = LanguageModel.createArray(
                    res.data,
                    new LanguageModel()
                );
                return new GeneralResponse('', false, false, data);
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$
    })
    getCountries(): Observable<{} | GeneralResponse> {
        const url = `${this._api}data/countries`;
        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                const data = CountryModel.createArray(
                    res.data,
                    new CountryModel()
                );
                return new GeneralResponse('', false, false, data);
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }
}
