import { Serializable } from "app/core/entities/serializable.model";
import { CountryModel } from "app/shared/models/country.model";


export class CrtClientModel extends Serializable {
    public id: number;
    public name: string;
    public address: string;
    public cep: string;
    public city: string;
    public cuit: string;
    public extra: string;
    public country_id: number;
    public country: CountryModel;

    newModel(data: any): CrtClientModel {
        const model = new CrtClientModel(data);

        if (data.country) {
            model.country = CountryModel.createOne(data.country,new CountryModel());
        } else {
            model.country = null;
        }
        return model;
    }

    prepareForStore(): any {
        return {
            id: this.id ?? null,
            name: this.name,
            address: this.address,
            cep: this.cep,
            city: this.city,
            cuit: this.cuit,            
            extra: this.extra,
            country_id: this.country_id,
        };
    }

    static createEmpty(): CrtClientModel {
        const client = new CrtClientModel();
        client.name = "";
        client.address = "";
        client.cep = "";
        client.city = "";
        client.cuit = "";
        client.extra = "";
        client.country_id = 1;

        return client;
    }
}
