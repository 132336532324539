import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './home.component';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {FuseWidgetModule} from "../../../@fuse/components";
import { CardCountComponent } from './card-count/card-count.component';
import { MaterialModule } from 'app/shared/material.module';
import { NgxTolgeeModule } from '@tolgee/ngx';


@NgModule({
    declarations: [HomeComponent, CardCountComponent],
    imports: [
        CommonModule,
        HomeRoutingModule,
        FuseSharedModule,
        MaterialModule,
        FuseWidgetModule,
        NgxTolgeeModule.forRoot({
            staticData: {
                es: () => import("../../../assets/i18n/es.json"),
                pt: () => import("../../../assets/i18n/pt.json"),
            },
            apiUrl: 'https://app.tolgee.io',
            apiKey: 'tgpak_ge2tiok7mfsg2zdtofwwe3bugjyw65lgni2hezdogbwgc4btha',
            ui: 'tgpak_ge2tiok7mfsg2zdtofwwe3bugjyw65lgni2hezdogbwgc4btha' ? require('@tolgee/ui') : undefined
          }), 
    ]
})
export class HomeModule {
}
