import {Serializable} from '../../core/entities/serializable.model';


export class CountryModel extends Serializable {

    public id: number;
    public name: string;
    public tag: string;
    public code: string;



    newModel(data: any): CountryModel {
        return new CountryModel(data);
    }

    static createEmpty(): CountryModel {
        const country = new CountryModel();
        country.name = "";
        country.tag = "";
        country.code = "";

        return country;
    }

}