import {Serializable} from '../../core/entities/serializable.model';

export class TrailerModel extends Serializable {

    public plateNumber: string;
    public tara: string;
    public titleFile: string;
    public brand: string;
    public year: number;
    public color: string;
    public insuranceFile: string;
    public techniqueDueDate: string;


    newModel(data: any): TrailerModel {
        return new TrailerModel(data);
    }


    prepareForStore(): any {
        return {
            'plate_number': this.plateNumber,
            'tara': this.tara,
            'title_file_base64': this.titleFile,
            'brand': this.brand,
            'year': this.year,
            'color': this.color,
            'insurance_file_base64': this.insuranceFile,
            'technique_due_date': this.techniqueDueDate
        };
    }
}
