import { Serializable } from "../../core/entities/serializable.model";
import { RolModel } from "./rol.model";
import { LanguageModel } from "./language.model";
import { ChatMessage } from "./chat-message.model";

export class ShortUserModel extends Serializable {
    public id: number;
    public fullName: string;
    public name: string;
    public lastName: string;
    public email: string;
    public languageId: number;
    public password: string;
    public passwordConfirmation: string;
    public roleId: number;

    public selected = false;

    public sendNewOrderEmails: number;
    public sendChatEmails: number;
    public sendPhotoPendingEmails: number;
    public sendCrossoverEmails: number;
    public sendFollowUpEmails: number;

    newModel(data: any): ShortUserModel {
        const dataParsed = new ShortUserModel(data);
        return dataParsed;
    }

    prepareForStore(): any {
        return {
            name: this.name,
            last_name: this.lastName,
            email: this.email,
            language_id: this.languageId,
            role_id: this.roleId,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            send_new_order_emails: this.sendNewOrderEmails,
            send_chat_emails: this.sendChatEmails,
            send_photo_pending_emails: this.sendPhotoPendingEmails,
            send_crossover_emails: this.sendCrossoverEmails,
            send_follow_up_emails: this.sendFollowUpEmails
        };
    }

    prepareForUpdate(): any {
        return {
            name: this.name,
            last_name: this.lastName,
            email: this.email,
            language_id: this.languageId,
            role_id: this.roleId,
            send_new_order_emails: this.sendNewOrderEmails,
            send_chat_emails: this.sendChatEmails,
            send_photo_pending_emails: this.sendPhotoPendingEmails,
            send_crossover_emails: this.sendCrossoverEmails,
            send_follow_up_emails: this.sendFollowUpEmails
        };
    }
}
