<fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget"
             fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-md="100">

  <!-- Front -->
  <div class="fuse-widget-front">
    <div class="pl-16 pr-8 py-16 h-52  text-center" fxLayout="row"
         fxLayoutAlign="space-between center">

      <label>{{title}}</label>
      <button mat-icon-button fuseWidgetToggle aria-label="more">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <div
        *ngIf="loading"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="mb-8"
    >
      <mat-spinner color="accent" [diameter]="50"></mat-spinner>
    </div>

    <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center" *ngIf="!loading">
      <div class="font-size-72 line-height-72" [ngClass]="color">
        {{count}}
      </div>
      <div class="h3 secondary-text font-weight-500"> {{ "orders_key" | translate:"Pedidos" }}
      </div>
    </div>

    <div class="p-16 border-top" fxLayout="row" fxLayoutAlign="start center" *ngIf="!loading">
      <span class="h4 secondary-text text-truncate">{{totalText}}: </span>
      <span class="h4 ml-8">{{total}}</span>
    </div>
  </div>


  <!-- Back -->
  <div class="fuse-widget-back p-16 pt-32" style="min-height: 244px">
    <button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button"
            aria-label="Flip widget">
      <mat-icon class="s-16">close</mat-icon>
    </button>

    <div>
      {{detail}}
    </div>
  </div>
  <!-- / Back -->

</fuse-widget>
